import React from 'react'
import { Link } from 'gatsby'
import Layout from '../../components/layout/layout'
import Header from '../../components/header/header'
import Nav from '../../components/nav/nav'
import Footer from '../../components/footer/footer'
import styles from './albums.module.css'

import paintingsThumb from '../../assets/images/albums/paintings-thumbnail.jpg'
import installationsThumb from '../../assets/images/albums/installations-thumbnail.jpg'
import ceramicsThumb from '../../assets/images/albums/ceramics-thumbnail.jpg'

export default () => (
  <Layout>
    <Header />
    <Nav />
    <main>
      <article className={styles.wrapper}>
        <div className={styles.galleryItem}>
          <Link to="/albums/paintings">
            <img
              src={paintingsThumb}
              alt="A thumbnail for the paintings gallery"
              className={styles.galleryThumbnail}
            />
          </Link>
          <h2 className={styles.caption}>Paintings</h2>
        </div>

        <div className={styles.galleryItem}>
          <Link to="/albums/installations">
            <img
              src={installationsThumb}
              alt="A thumbnail for the installation views gallery"
              className={styles.galleryThumbnail}
            />
          </Link>
          <h2 className={styles.caption}>Installation Views</h2>
        </div>

        <div className={styles.galleryItem}>
          <Link to="/albums/ceramics-and-mixed-media">
            <img
              src={ceramicsThumb}
              alt="A thumbnail for the ceramics and mixed media gallery"
              className={styles.galleryThumbnail}
            />
          </Link>
          <h2 className={styles.caption}>Ceramics and Mixed Media</h2>
        </div>
      </article>
    </main>
    <Footer />
  </Layout>
)
